import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import headerContentImage from '../assets/images/prisioneros-content-header.jpg';
import contentImage from '../assets/images/prisioneros-content.jpg';
import contentImage2 from '../assets/images/prisioneros-content-2.jpg';
import headerImage from '../assets/images/prisioneros-header.jpg';
import leftContentImage from '../assets/images/prisioneros-left-image.jpg';
import '../styles/PagePrisioneros.scss';
import { fontawesome } from '../utilities';
import { Link } from 'react-router-dom';    

const PagePrisioneros = () => {
    const { solidIcons, brandIcons } = fontawesome;
    return (
        <main className="prisioneros">
            <section id='header-section'>
                <div className="container header-container">
                    <img src={headerImage} alt="Los prisioneros header" title="Imagen header Los prisioneros" />
                </div>
            </section>
            <section id="content-section">
                <div className="container content-container">
                    <div className='row' >
                        <div className='col-12 col-md-4 content-container__left-content'>
                            <img className='img-fluid' src={leftContentImage} alt="Los prisioneros left content" title="Imagen izquierda Los prisioneros" />
                            <div className='content-container__left-content__btn-container'>
                                <Link to={'https://ventas.quickticket.com.co/?ev=zpbyvistmkbqhzwonacn4jct6q06w7&pven=1'}><button className='btn btn-primary btn-buy-ticket'><FontAwesomeIcon icon={solidIcons['faTicket']} /> COMPRAR BOLETA</button></Link>
                                <Link to={'https://wa.link/8ni0za'}><button className='btn btn-primary btn-buy-information'><FontAwesomeIcon icon={brandIcons['faWhatsapp']} /> INFORMACIÓN</button></Link>
                                <Link to={'https://ventas.quickticket.com.co/perfil.html'}><button className='btn btn-primary btn-buy-download'>DESCARGAR BOLETA</button></Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8 content-container__right-content'>
                            <h1 className='content-container__right-content__title'>Los prisioneros</h1>
                            <img className='img-fluid content-container__right-content__header-image' src={headerContentImage} alt="Los prisioneros header content" title="Imagen header Los prisioneros" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage} alt="Los prisioneros content" title="Imagen contenido Los prisioneros" />
                            <img className='img-fluid content-container__right-content__content-image' src={contentImage2} alt="Los prisioneros content" title="Imagen contenido Los prisioneros" />
                        
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default PagePrisioneros; 